@import "../../atoms/variables";
@import "../../atoms/animations";

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 5px 0 0;

    &__nav {
        width: 100%;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 30px;
        @media screen and(max-width: 768px) {
            flex-direction: column;
            row-gap: 10px;
            justify-content: space-around;
        }

        #JhowArenasLogo {
            max-width: 70px;
            max-height: 70px;
            display: flex;
            align-items: flex-end;
            &:hover {
                #LetterTContent{
                    animation: comeBackLeft 1s linear;
                    #LetterT {
                        animation: fillFade 5s linear infinite;
                    }
                }
                #LetterJContent{
                    animation: bouncing 1s linear;
                    #LetterJ {
                        animation: fillFade 5s linear infinite;
                    }
                }
                #LetterAContent {
                    animation:  comeBackRight 1s linear;
                    #LetterA {
                        animation: fillFade 5s linear infinite;
                    }
                }
            }
        }
    }
}