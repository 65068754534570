
@keyframes colorFade {
    0% {
        color: $green3;
    }
    25% {
        color: $orange3;
    }
    50% {
        color: $blue8;
    }
    75% {
        color: $orange3;
    }
    100% {
        color: $green3;
    }
}

@keyframes fillFade {
    0% {
        fill: $green3;
    }
    25% {
        fill: $orange3;
    }
    50% {
        fill: $blue8;
    }
    75% {
        fill: $orange3;
    }
    100% {
        fill: $green3;
    }
}

@keyframes bgFade {
    0% {
        background: $green3;
    }
    25% {
        background: $orange3;
    }
    50% {
        background: $blue8;
    }
    75% {
        background: $orange3;
    }
    100% {
        background: $green3;
    }
}

@keyframes rotateRight {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(720deg);
    }
}

@keyframes rotateLeft {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-720deg);
    }
}

@keyframes comeBack {
    0% {
        transform: scale(0.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes comeBackLeft {
    0% {
        transform: translateX(-1000px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes comeBackRight {
    0% {
        transform: translateX(1000px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
    }
    25% {
        transform: scale(0.7);
    }
    50% {
        transform: scale(0.9);
    }
    75% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bouncing {
    0% {
        transform: scaleY(0);
    }
    25% {
        transform: scaleY(.7);
    }
    50% {
        transform: scaleY(.6);
    }
    75% {
        transform: scaleY(.8);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes boxShadowFade {
    0% {
        box-shadow: 0 0 0 .1vw $green3;
    }
    25% {
        box-shadow: 0 0 0 .4vw $orange3;
    }
    50% {
        box-shadow: 0 0 0 .1vw $blue8;
    }
    75% {
        box-shadow: 0 0 0 .4vw $orange3;
    }
    100% {
        box-shadow: 0 0 0 .1vw $green3;
    }
}

@keyframes rotateLeft {
    0% {
        transform: rotate(-90deg);
    }
    50% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(-90deg);
    }
}

@keyframes rotateRight {
    0% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes shadowRotate {
    0% {
        box-shadow: 0 2px 0 0.5px $green3;
    }
    25% {
        box-shadow: 2px 0 0 0.5px $orange3;
    }
    50% {
        box-shadow: 0 -2px 0 0.5px $blue8;
    }
    75% {
        box-shadow: -2px 0 0 0.5px $orange3;
    }
    100% {
        box-shadow: 0 2px 0 0.5px $green3;
    }
}

@keyframes flow {
    0% {
      background-position: 0 50%
    }
    
    50% {
      background-position: 50% 100%
    }
    
    100% {
      background-position: 0 50%
    }
  }