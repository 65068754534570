$blue1:#000415;
$blue2:#000439;
$blue3:#00005B;
$blue4:#11017A;
$blue5:#2B0698;
$blue6:#4C0DB3;
$blue7:#A585DA;
$blue8:#D2C2ED;

$orange1: #FF512F;
$orange2: #F09819;
$orange3: #f2c17b;

$green1: #061700;
$green2: #52c234;
$green3: #a2ee8d;
$green4: #ffffff;

@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap'); // logo 
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$font1: 'Satisfy', sans-serif;
$font2: 'Caveat', sans-serif;
$font3: 'Open Sans', sans-serif;
