@import "../../atoms/variables";
@import "../../atoms/animations";

.about {
    padding: 0;
    margin: 0;
    padding: 110px 0;
    display: flex;
    align-content: stretch;
    justify-content: center;
    
    top: 0;
    bottom: 0;

    left: auto;
    right: -50vw;

    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    max-width: 100vw;
    transition: all .6s ease-in-out;
    @media screen and (max-width: 768px) {
        transform: scale(0) rotate(100deg);
    }

    &.active {
        right: 0;
        opacity: 1;
        visibility: visible;
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        transform: scale(1);
        transition: all 1s ease-in-out;
        @media screen and (max-width: 768px) {
            transform: scale(1) rotate(0);
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        max-width: 1140px;
        margin: 0 30px;
        padding: 20px;

        @media screen and (max-width: 768px) {
            padding: 10px;
        }
    }

    &__content {
        max-width: 100%;

        h2 {
            font-size: 50px;
            font-family: $font1;
            color: $green3;
            text-transform: uppercase;
            position: relative;

            @media screen and (max-width: 488px) {
                font-size: 30px;
            }

            @media screen and (max-width: 320px) {
                font-size: 20px;
            }

            svg {
                margin: 0 0 0 10px;
                max-width: 20px;
                max-height: 20px;

                path {
                    fill: $green3 !important;
                }
            }

            &:hover {
                span {
                    opacity: 1;
                    visibility: inherit;
                    top: 100%;
                }
            }

            span {
                position: absolute;
                font-size: 12px;
                color: #000;
                background-color: $green3;
                border: 1px solid $green2;
                box-shadow: 0 0 15px 0 $green2;
                padding: 10px 8px;
                top: 150%;
                width: 100%;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transition: all .4s ease-in-out;

                &::after {
                    content: "";
                    position: absolute;
                    top: -10px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $green2;
                }
            }
        }

        p {
            font-size: 20px;
            font-weight: 400;
            margin: 10px 0;
            color: $green4;

            @media screen and (max-width: 488px) {
                font-size: 16px;
            }

            @media screen and (max-width: 320px) {
                font-size: 13px;
            }
        }
    }

    blockquote {
        margin: 20px 0 0;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        border-left: 4px solid $green3;
        padding: 0 0 0 15px;
        width: 100%;
        color: $green3;

        @media screen and (max-width: 488px) {
            font-size: 13px;
        }

        @media screen and (max-width: 320px) {
            display: none;
        }
    }
}