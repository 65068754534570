@import "../../atoms/variables";
@import "../../atoms/animations";

.links{
    &__list{
        display: flex;
        list-style: none;
        gap: 5px;
        @media screen and (max-width: 330px) {
            // flex-direction: column;
        }
        &--item{
            padding: 2px;
            // margin: 0 4px;
            box-shadow: none;
            display: flex;
            align-items: center;
            &:hover{
                animation: shadowRotate 1s ease-in-out infinite;
                svg{
                    path{
                        animation: fillFade 1s ease-in-out infinite;
                    }
                }
            }
            &.active{
                color: $green3;
            }
            a{
                display: flex;
                align-items: center;
                svg{
                    &#github-logo{
                        max-width: 40px;
                        max-height: 32px;
                    }
                    max-width: 30px;
                    max-height: 30px;
                    path{
                        fill: $green4;
                    }
                }
            }
        }
    }   
}