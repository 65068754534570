@import "../../atoms/variables";
@import "../../atoms/animations";

.experience {
    padding: 0;
    margin: 0;
    padding: 110px 0;
    display: flex;
    align-content: stretch;
    justify-content: center;
    max-width: 100vw;
    
    top: 0;
    bottom: 0;

    right: auto;
    left: -50vw;

    opacity: 0;
    visibility: hidden;
    transform: scale(0);

    transition: all .6s ease-in-out;

    @media screen and (max-width: 768px) {
        transform: scale(0) rotate(-100deg);
    }

    &.active {
        left: 0;
        opacity: 1;
        visibility: visible;
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        transform: scale(1);
        transition: all 1s ease-in-out;

        @media screen and (max-width: 768px) {
            transform: scale(1) rotate(0);
        }
    }


    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        max-width: 1140px;
        margin: 0 30px;
        padding: 20px;

        @media screen and (max-width: 768px) {
            padding: 10px;
        }
    }

    &__list {}
}