@import "./components/atoms/_variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font3;
}

body {
  position: sticky;
  top: 0;
  background-color: $blue1;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 150%;
  height: 100vh;
  overflow: hidden;
}

section{
  position: fixed;
  z-index: 1;
}

svg {
  &#JhowArenasLogo {
    max-width: 100px;
    color: #ffffff;
    max-height: 100px;
    height: 100px;
    width: 100px;

    * {
      fill: $green3;
    }
  }
}