@import "../../atoms/variables";
@import "../../atoms/animations";

.company {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    color: $green3;
    font-size: 28px;
    margin: 0 0 5px;
    @media screen and (max-width: 330px) {
      font-size: 22px;
    }
  }

  &__time {
    color: $orange3;
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 5px;
    @media screen and (max-width: 330px) {
      font-size: 13;
    }
  }

  &__positions {
    padding: 0 0 0 35px;
    &--item {
      list-style-type: disclosure-closed;
      list-style-position: outside;
      color: $orange3;
      .position {
        &__name {
          color: $green4;

          @media screen and (max-width: 330px) {
            font-size: 12px;
          }
        }
        &__desc {
          height: 0;
          overflow: hidden;
          visibility: hidden;
          transition: all 1s ease-in-out;
          color: $green4;
        }
      }
    }
  }
}
