@import "../../atoms/_variables.scss";
@import "../../atoms/animations.scss";

.intro {
    padding: 0;
    position: relative;
    
    max-height: 0;
    transform: scale(0);
    transition: all .5s ease-in-out;

    &.active {
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        transform: scale(1);
        transition: all 1s ease-in-out;
    }

    &__header {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        max-width: 1200px;
        margin: 0 auto;

        h1 {
            font-size: 70px;
            padding: 1vw;
            max-width: 70vw;
            color: $green4;
            font-family: $font1;
            text-align: center;

            @media screen and (max-width: 488px) {
                font-size: 35px;
            }
        }

        h2 {
            font-size: 40px;
            color: $green4;
            padding: 1vw;
            font-family: $font2;
            max-width: 70vw;
            text-align: center;

            @media screen and (max-width: 488px) {
                font-size: 25px;
            }
        }

        p {
            font-size: 20px;
            font-family: $font2;
            color: $green4;
            max-width: 250px;
            text-align: center;
        }

        &::after,
        &::before {
            content: "";
            position: absolute;
            width: 70vw;
            height: 70vw;
            max-width: 70vh;
            max-height: 70vh;
            transform: rotate(-90deg);
            box-shadow: 0 0 0 1px $green4;
            animation:
                rotateLeft 40s ease-in-out infinite;
            z-index: -1;

            @media screen and (max-width: 488px) {
                width: 90vw;
                height: 90vw;
                max-width: 90vh;
                max-height: 90vh;
            }
        }

        &::before {
            transform: rotate(90deg);
            animation:
                rotateRight 40s ease-in-out infinite;
        }

        &:hover {

            h1,
            h2 {
                animation: colorFade 5s ease-in-out infinite;
            }

            &::after {
                animation: boxShadowFade 5s ease-in-out infinite,
                    rotateLeft 40s ease-in-out infinite;
            }

            &::before {
                animation: boxShadowFade 5s ease-in-out infinite,
                    rotateRight 40s ease-in-out infinite;
            }
        }
    }
}