@import "../../atoms/variables";
@import "../../atoms/animations";

.navbar {
    display: flex;

    li {
        list-style: none;
        margin: 0 10px;

        &.active {
            a {
                color: $green3;
                &::after {
                    width: 100%;
                    background-color: $green3;
                }
            }
        }

        a {
            text-decoration: none;
            color: $green4;
            font-size: 28px;
            font-family: $font2;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 0;
                height: 2px;
                background: $green4;
                transition: width 1s ease-in-out;
            }

            &:hover {
                animation: colorFade 5s ease-in-out infinite;

                &::after {
                    width: 100%;
                    animation: bgFade 5s ease-in-out infinite;
                }
            }
        }
    }
}