@import "../../atoms/variables";
@import "../../atoms/animations";

.languages{
    &__list{
        display: flex;
        list-style: none;
        gap: 5px;

        @media screen and (max-width: 330px) {
            flex-direction: column;
        }
        &--item{
            padding: 5px;
            font-size: 28px;
            line-height: 28px;
            font-weight: 600;
            color : $green4;
            cursor: pointer;
            box-shadow: none;
            font-family: $font1;
            &:hover{
                animation: colorFade 1s ease-in-out infinite,
                shadowRotate 1s ease-in-out infinite;
            }
            &.active{
                color: $green3;
            }
        }
    }   
}