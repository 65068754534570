footer{
    &.footer{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        padding: 20px 30px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            padding: 10px;
        }
    }
}